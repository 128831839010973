import ContentLoader from "react-content-loader";

export const TextLoader = () => (
  <ContentLoader 
    speed={2}
    width={"100%"}
    height={60}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="3" ry="3" width="15%" height="11" /> 
    <rect x="20%" y="0" rx="3" ry="3" width="70%" height="11" /> 
    <rect x="127" y="48" rx="3" ry="3" width="53" height="11" /> 
    <rect x="-6" y="47" rx="3" ry="3" width="100" height="11" /> 
    <rect x="0" y="22" rx="3" ry="3" width="70%" height="11" /> 
    <rect x="80%" y="23" rx="3" ry="3" width="10%" height="11" />
  </ContentLoader>
)