import React from "react";
import Course from "./Course";
import { ApiDataContext } from "../Hooks/useContext";

import { Course as TCourse } from "../types";
import { useContext } from "react";
import CourseLoading from "./Course-loading";

function Courses() {
  const { loading, data, error } = useContext(ApiDataContext);
  
  return (
    <div className="flex flex-col gap-4 md:flex-row md:justify-between md:flex-wrap">
      {!loading ? data.courses.map((course:TCourse) => {
        return (
          <Course 
          name={course.name} 
          key={course.name} 
          embed={course.embed} />
        );
      }) : <>
          <CourseLoading />
          <CourseLoading />
          <CourseLoading />
      </>}
    </div>
  );
}

export default Courses;
