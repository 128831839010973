import React from "react";

type Props = {
  Icon:string,
  target:string
}

function SocialLink({ Icon, target }:Props) {
  return (
    <a
      href={target}
      target="__blank"
      className="font-normal text-gray-400 hover:text-gray-100"
    >
      {<Icon size={25} />}
    </a>
  );
}

export default SocialLink;
