import React, { useContext } from "react";
import Card from "./Card";
import { Framework as TFramework } from "../types";
import { ApiDataContext } from "../Hooks/useContext";
import { Facebook } from "react-content-loader";
import CardLoading from "./Card-loading";
import Title from "./Title";

function Frameworks() {
  const { loading, data, error } = useContext(ApiDataContext);

  return (
    <>
      <div className="flex justify-around flex-wrap gap-2">
        <Title text="Web Frameworks" show={loading} />

        {!loading ? (
          data.frameworks.map((framework: TFramework) => {
            return <Card key={framework} name={framework} />;
          })
        ) : (
          <CardLoading />
        )}
      </div>
    </>
  );
}

export default Frameworks;
