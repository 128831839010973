import React from "react";

// type Props = {
//   name: string;
//   Icon: string;
//   target: string;
// };

function MenuLink({ data }) {
  return (
    <ul className="font-bold text-indigo-200 dark:text-indigo-200 text-2x">
      {data.menus.map((menu) => {
        return (
          <li className="m-2 px-8 py-4 text-2xl uppercase hover:underline hover:scale-125 hover:duration-300">
            <a href={`#${menu.target}`}>{menu.name}</a>
          </li>
        );
      })}
    </ul>
  );
}

export default MenuLink;
