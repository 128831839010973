import React from "react";

type Props = {
  children: string;
  target: string;
};

function Heading(props) {
  return (
    <h1
      className="text-2xl font-bold p-2 uppercase underline 
      text-accent dark:text-yellow-400 
      decoration-accent dark:decoration-indigo-400 
      decoration-wavy decoration-3 my-2 hover:decoration-4 
      hover:text-indigo-700 dark:hover:text-yellow-500"
      id={props.target}
    >
      {props.children}
    </h1>
  );
}

export default Heading;
