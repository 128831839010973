import React from "react";

type Props = {
  name:string
  embed:string,
}

function YoutubeIframe({ name, embed }:Props) {
  return (
    <iframe
      className="w-full h-48 rounded-t-lg aspect-w-16 aspect-h-9"
      src={`${"https://www.youtube.com/embed/" + embed}`}
      title={name}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen={true}
    ></iframe>
  );
}

export default YoutubeIframe;
