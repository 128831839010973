import React, { useContext } from "react";
import { BsImage } from "react-icons/bs";
import Loading from "./Loading";
import { Instagram } from "react-content-loader";
import { ApiDataContext } from "../Hooks/useContext";
import { List } from "react-content-loader";

type Props = {
  name: string;
  link: string;
  image: string;
  technologies: string[];
  // loading:boolean
};

function Project({ name, link, image, technologies }: Props) {
  const { loading, data, error } = useContext(ApiDataContext);
  return (
    <div className="w-full md:w-[48%] lg:w-[30%]  bg-white dark:bg-slate-800 rounded-lg shadow-md">
      <div className="group relative object-cover">
        {!loading ? (
          <img
            className="rounded-t-lg min-h-48 object-cover"
            src={"/images/" + image + ".png"}
            alt={name}
          />
        ) : (
          <Loading Icon={BsImage} />
        )}

        <div className="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-indigo-700 opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
          <a
            className="mt-5 px-8 py-3 rounded-full bg-amber-400 hover:bg-amber-600 duration-300"
            href={link}
          >
            Visit Site
          </a>
        </div>
      </div>

      <div className="p-3">
        <a href={link}>
          <h5
            className="mb-2 text-2xl font-bold tracking-tight
           text-indigo-700 dark:text-indigo-100 dark:hover:text-yellow-400"
          >
            {name}
          </h5>
        </a>
        <p className="m-2 text-md font-normal text-gray-700 dark:text-indigo-400">
          Technologies
        </p>
        <ul className="flex px-3 gap-2 flex-wrap justify-around font-normal text-gray-700 dark:text-gray-400    ">
          {!loading ? (
            technologies.map((name) => {
              return (
                <li
                  key={name}
                  className="py-1 px-4 bg-gray-200  dark:bg-slate-600 rounded-full hover:border-indgo-800 hover:border-yellow-400 hover:text-indigo-400"
                >
                  {name}
                </li>
              );
            })
          ) : (
            <List width={"w-full"} />
          )}
        </ul>
      </div>
    </div>
  );
}

export default Project;
