import React, { useContext } from "react";
import Courses from "../components/Courses";
import Frameworks from "../components/Frameworks";
import Heading from "../components/Heading";
import Languages from "../components/Languages";
import Projects from "../components/Projects";
import Tools from "../components/Tools";
import useDarkMode from "../Hooks/useDarkMode";
import { BiSun } from "react-icons/bi";
import { BsFillMoonStarsFill } from "react-icons/bs";
import ContactForm from "../components/ContactForm";
import { ApiDataContext } from "../Hooks/useContext";
import { Code, Facebook } from "react-content-loader";
import { TextLoader } from "../components/loaders/Text-loader";
import Certifications from "../components/Certifications";
import Applications from "../components/Applications";

function Main() {
  const [theme, setTheme] = useDarkMode();
  const { loading, data, error } = useContext(ApiDataContext);

  const ButtonText =
    theme === "light" ? (
      <BsFillMoonStarsFill
        className="hover:rotate-180 fill-gray-700 transition hover:duration-250 ease-in-out"
        size={20}
      />
    ) : (
      <BiSun
        className="hover:animate-spin transition hover:duration-250 ease-in-out"
        size={20}
      />
    );

  return (
    <div className="container scroll-smooth">
      <div className="mx-4 p-4">
        <div className="flex justify-between">
          <Heading target="#">Summary</Heading>
          <div className="flex my-2 dark:bg-transparent bg-gray-100">
            <button
              onClick={setTheme}
              className="px-4 font-semibold border-dashed border-2 border-indigo-500
            dark:text-white rounded-md shadow-sm"
            >
              {ButtonText}
            </button>
          </div>
        </div>
        <p className="p-2 text-xl text-primary dark:text-indigo-100 whitespace-pre-line">
          {!loading ? data.summary : <TextLoader />}
        </p>
      </div>

      <div className="mx-4 p-4">
        <Heading target="skills">Technical Skills</Heading>
        <div className="flex flex-col md:flex-row gap-8 text-center">
          <Languages />
          <Frameworks />
          <Applications />
          <Tools />
        </div>
      </div>

      <div className="mx-4 p-4">
        <Heading target="certifications">Professional Certifications</Heading>
        <Certifications />
      </div>

      <div className="mx-4 p-4">
        <Heading target="projects">Recent Projects</Heading>
        <div className="flex flex-col md:flex-row gap-8 text-center">
          <Projects />
        </div>
      </div>

      <div className="mx-4 p-4">
        <Heading target="courses">Youtube Courses</Heading>
        <div className="flex flex-col md:flex-row gap-8 text-center">
          <Courses />
        </div>
      </div>

      <div className="mx-4 p-4">
        <Heading target="contact">Contact</Heading>
        <div className="">
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default Main;
