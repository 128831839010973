import React,{useContext} from "react";
import { ApiDataContext } from "../Hooks/useContext";
import { List } from 'react-content-loader';

function CardLoading() {

  const { loading} = useContext(ApiDataContext);
  
  return (
    <div
      className={`w-full p-4`}
    >
     <List width={`w-full`}/>
              

    </div>
  );
}

export default CardLoading;
