import React, { useState, createContext, useEffect } from "react";
import useFetch from "./useFetch";

const ApiDataContext = createContext();

function APIDataContextProvider(props) {
  const { loading, data, error } = useFetch();

  return (
    <ApiDataContext.Provider value={{ loading, data, error }}>
      {props.children}
    </ApiDataContext.Provider>
  );
}

export { APIDataContextProvider, ApiDataContext };
