import React,{useContext} from "react";
import Card from "./Card";
import { Tool as TTool } from "../types";
import { ApiDataContext } from "../Hooks/useContext";
import CardLoading from "./Card-loading";
import Title from "./Title";



function Tools() {
  const { loading, data } = useContext(ApiDataContext);

  return (
    <>
      <div className="flex justify-around flex-wrap gap-2">
          <Title text="Tools" show={ loading}/>
        {!loading ? data.tools.map((tool:TTool) => {
          return <Card 
            key={tool} 
            name={tool} />;
        }): <CardLoading />}
      </div>
    </>
  );
}

export default Tools;
