import React from "react";
import Loading from "./Loading";
import { BsImage } from "react-icons/bs";

type Props = {
  name: string;
  key: string;
};

function Card(props: Props) {
  return (
    <div
      className="w-full p-4 bg-indigo-900 dark:bg-slate-800 max-h-16  rounded-full text-accent border-indigo-700 
     dark:hover:bg-slate-700 dark:hover:bg-transparent
      transition duration-200 
    ease-in-out text-gray-200 dark:text-indigo-200 font-bold hover:translate-x-2"
    >
      {props.name}
    </div>
  );
}

export default Card;
