import React, { useContext } from "react";
import SocialLink from "../components/SocialLink";
import { BsGithub, BsLinkedin, BsYoutube } from "react-icons/bs";
import MenuLink from "../components/MenuLink";
import { ApiDataContext } from "../Hooks/useContext";
import { List } from "react-content-loader";

function Sidebar(props) {
  const { loading, data } = useContext(ApiDataContext);

  return (
    <div className="flex flex-col">
      <div className="mt-4 flex justify-center">
        <img
          src={"/images/profile.jpeg"}
          alt="Profile"
          className="w-64 rounded-full transform transition duration-500 hover:scale-[110%]"
        />
      </div>

      <div className="my-3 text-center text-white">
        <i className="text-md leading-loose font-normal">Hello, I’m </i>
        <h1 className="text-2xl font-extrabold hover:text-yellow-400">
          Abdifatah Awil
        </h1>
        <p
          className="text-indigo-300 my-2 text-xl  underline decoration-accent 
        decoration-wavy decoration-3 hover:decoration-4 hover:text-yellow-400"
        >
          FullStack Developer <br /> Data Analyst
        </p>
      </div>

      <div className="flex justify-center align-top gap-4 mb-4">
        <SocialLink
          Icon={BsGithub}
          target="https://www.github.com/abdifatahz"
        />
        <SocialLink
          Icon={BsLinkedin}
          target="https://www.linkedin.com/in/amiirfahad/"
        />

        <SocialLink
          Icon={BsYoutube}
          target="https://www.youtube.com/c/Somcoders/"
        />
      </div>

      <div className="my-2 hidden sm:flex justify-center">
        {!loading ? <MenuLink data={data} /> : <List />}
      </div>
    </div>
  );
}

export default Sidebar;
