import React from "react";

type Props = {
  Icon:string
}


function Loading({ Icon }:Props) {
  return (
    <div className="animate-pulse border border-blue-300 p-4 shadow rounded-t-lg h-48 bg-gray-400">
      <div className="flex h-full items-center justify-center">
        <Icon size={50} className="text-gray-200" />
      </div>
    </div>
  );
}

export default Loading;
