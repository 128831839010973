import React,{useContext} from "react";
import { BsImage } from "react-icons/bs";
import Loading from "./Loading";
import { List } from 'react-content-loader';


function ProjectLoading() {
  return (
    <div className="w-full md:w-[48%] lg:w-[30%]  bg-white dark:bg-dark rounded-lg border border-gray-200 dark:border-indigo-400 shadow-md">
        {<Loading Icon={BsImage} />}

        <div className="absolute top-0 left-0 w-full h-0 flex flex-col justify-center items-center bg-indigo-700 opacity-0 group-hover:h-full group-hover:opacity-80 duration-500">
          <a
            className="mt-5 px-8 py-3 rounded-full bg-amber-400 hover:bg-amber-600 duration-300"
            href={"link"}
          >
            Visit Site
          </a>
        </div>

      <div className="p-3">
        <ul className="flex px-3 gap-2 flex-wrap justify-around font-normal text-gray-700 dark:text-gray-400    ">
          {<List width={'w-full'}/>}
        </ul>
      </div>
    </div>
  );
}

export default ProjectLoading;
