import React, { useContext } from "react";
import Project from "./Project";
import { Project as TProject } from "../types";
import { ApiDataContext } from "../Hooks/useContext";
import { Instagram } from "react-content-loader";
import ProjectLoading from "./Project-loading";

function Projects() {
  const { loading, data, error } = useContext(ApiDataContext);

  return (
    <div className="flex flex-col gap-4 md:flex-row md:justify-between md:flex-wrap">
      {!loading ? (
        data.projects.map((project: TProject) => {
          return (
            <Project
              key={project.name}
              name={project.name}
              link={project.link}
              image={project.image}
              technologies={project.technologies}
            />
          );
        })
      ) : (
        <>
          <ProjectLoading />
          <ProjectLoading />
          <ProjectLoading />
        </>
      )}
    </div>
  );
}

export default Projects;
