import React from "react";


type Props = {
  name:string
  Icon:string,
  target:string
}

function NavElement({ name, target, Icon }:Props) {
  return (
    <a
      href={`#${target}`}
      className="flex flex-col hover:bg-primary dark:hover:bg-gray-900 items-center w-1/4 
      uppercase bg-indigo-800 dark:bg-gray-800 px-2 font-semibold"
    >
      <span className="m-1 text-white">{Icon}</span>
      <p>{name}</p>
    </a>
  );
}

export default NavElement;
