import React,{useContext} from "react";
import Card from "./Card";
import { Language as TLanguage } from "../types";
import { ApiDataContext } from "../Hooks/useContext";
import { Facebook } from 'react-content-loader';
import CardLoading from "./Card-loading";
import Title from "./Title";


function Languages() {
  const { loading, data } = useContext(ApiDataContext);

  return (
    <>
      <div className="flex justify-around flex-wrap gap-2">
        <Title text="Programming Languages" show={ loading}/>
         {!loading ? data.languages.map((lang:TLanguage) => {
          return <Card 
            key={lang} 
            name={lang} />;
        }): <CardLoading />}
      </div>
    </>
  );
}

export default Languages;
