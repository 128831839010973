import React, { useContext } from "react";
import { Tool as TTool } from "../types";
import { ApiDataContext } from "../Hooks/useContext";
import CardLoading from "./Card-loading";
import Certificate from "./Certificate";

function Certifications() {
  const { loading, data } = useContext(ApiDataContext);

  return (
    <>
      <div className="flex justify-around flex-wrap gap-2">
        {!loading ? (
          data.certifications.map((tool: TTool) => {
            return (
              <Certificate
                key={tool}
                name={tool.name}
                link={tool.link}
                link_text={tool.link_text}
              />
            );
          })
        ) : (
          <CardLoading />
        )}
      </div>
    </>
  );
}

export default Certifications;
