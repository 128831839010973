import { useContext } from "react";
import NavElement from "./components/NavElement";
import Main from "./layouts/Main";
import Sidebar from "./layouts/Sidebar";
import { ApiDataContext } from "./Hooks/useContext";
import {
  BiHomeCircle,
  BsListUl,
  CgListTree,
  IoBookOutline,
} from "./components/icons"


function App() {

  const { loading, data, error } = useContext(ApiDataContext);

    const icons = {
      "<BiHomeCircle />": <BiHomeCircle size={25}/>,
      "<BsListUl />": <BsListUl   size={25}/>,
      "<CgListTree />": <CgListTree   size={25}/>,
      "<IoBookOutline />": <IoBookOutline   size={25}/>,
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row">
        <aside className="sm:fixed bottom-0 top-0 bg-primary dark:bg-gray-800  w-full sm:w-1/4">
          <Sidebar />
        </aside>
        <main className="sm:ml-[25%] bg-secondary dark:bg-gray-900 w-full sm:w-3/4 sm:h-full">
          <Main />
        </main>
      </div>

      <nav className="sm:hidden fixed bottom-0 w-full shadow-inner">
        <div className="flex justify-between text-center w-full gap-1 bg-primary  dark:bg-dark text-white">
          {!loading ? data.menus
            .filter((menu) => menu.visible)
            .map((menu) => (
              <NavElement
                name={menu.name}
                key={menu.name}
                target={menu.target}
                Icon={icons[menu.icon]}
              />
            )) : "Loading"}
        </div>
      </nav>
    </>
  );
}

export default App;
