import { useEffect, useState } from "react";

function useDarkMode() {
  const LIGHT = "light";
  const DARK = "dark";
  const COLOR_SCHEME_QUERY = "(prefers-color-scheme:DARK";
  const IS_OS_THEME_IS_DARK = window.matchMedia(COLOR_SCHEME_QUERY).matches;

  // const [theme, setTheme] = useState(IS_OS_THEME_IS_DARK ? DARK : LIGHT);
  const [theme, setTheme] = useState(DARK);

  const themeSwitcher = theme === LIGHT ? DARK : LIGHT;

  function changeMode() {
    localStorage.setItem("theme", themeSwitcher);
    setTheme(themeSwitcher);
  }

  useEffect(() => {
    const HTML_ELEMENT = document.documentElement;

    if (
      localStorage.theme === DARK ||
      (!("theme" in localStorage) &&
        window.matchMedia(COLOR_SCHEME_QUERY).matches)
    ) {
      HTML_ELEMENT.classList.add(DARK);
    } else {
      HTML_ELEMENT.classList.remove(DARK);
    }
  }, [theme]);

  return [theme, changeMode];
}

export default useDarkMode;
