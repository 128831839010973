import { useState, useEffect } from "react";

function useFetch() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [error, setError] = useState("");

  const JSONPIN_API_KEY = process.env.REACT_APP_JSONPIN_API_KEY;
  // const JSONPIN_ACCESS_KEY = process.env.REACT_APP_JSONPIN_ACCESS_KEY;

  const url = `https://api.jsonbin.io/v3/b/${JSONPIN_API_KEY}`;

  useEffect(() => {
    fetch(url, {
      headers: {
        "Content-Type": "application/json",
        "X-Schema-Doc-Name": "Sample Schema Doc",
        "X-ACCESS-KEY":
          "$2b$10$aFWj9a5Bzi1HXKK9TpOiluSMnaHtFFxeYqDK0SjCuYdeWhg9HCUSS",
      },
    })
      .then((res) => res.json())
      .then((data) => setData(data.record))
      .catch((err) => setError(err))
      .finally(() => setLoading(false));
  }, [url]);

  return { loading, data, error };
}

export default useFetch;
