import React from "react";
import { RiVideoLine } from "react-icons/ri";
import Loading from "./Loading";
import { Code } from 'react-content-loader'


function CourseLoading() {

  return (
    <div className="w-full md:w-[48%] lg:w-[30%]  bg-white dark:bg-dark rounded-lg border border-gray-200 dark:border-indigo-400 shadow-md">
      <div className="object-cover">
        {
          <Loading Icon={RiVideoLine} />}
      </div>

      <div className="p-3">
          <h5 className="mb-2 hover:underline hover:text-indigo-800 dark:hover:text-yellow-400 dark:text-indigo-200  hover:duration-100 text-2xl font-bold tracking-loose text-indigo-700">
            <Code width={`w-full`}/>
          </h5>
      </div>
    </div>
  );
}

export default CourseLoading;
