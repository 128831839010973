import React,{useContext} from "react";

function Title({text,show}) {
  return (
        <p className="text-xl font-semibold p-4 w-full text-primary dark:text-indigo-200">
            {!show ? text : ''}
        </p>
  );
}

export default Title;
