import React from "react";
import Loading from "./Loading";
import { BsImage } from "react-icons/bs";

type Props = {
  name: string;
  link: string;
  link_text: string;
  key: string;
};

function Certificate(props: Props) {
  return (
    <div
      className="flex justify-between w-full p-4 bg-indigo-900 dark:bg-slate-800 max-h-16  rounded-full text-accent border-indigo-700 
     dark:hover:bg-slate-700 dark:hover:bg-transparent
      transition duration-200 
    ease-in-out text-gray-200 dark:text-indigo-200 font-bold hover:translate-x-2"
    >
      <p>{props.name}</p>
      <p>
        <a
          className={props.link !== "" ? "underline" : ""}
          href={props.link ? props.link : "#"}
        >
          {props.link_text}
        </a>
      </p>
    </div>
  );
}

export default Certificate;
