import React from "react";
import { useForm, ValidationError } from "@formspree/react";
function ContactForm() {
  const [state, handleSubmit] = useForm("mbjbrpzv");
  if (state.succeeded) {
    return (
      <p className="font-bold  text-center text-indigo-500 text-2xl">
        Thanks for contacting!
      </p>
    );
  }
  return (
    <form
      onSubmit={handleSubmit}
      className="bg-white dark:bg-dark shadow-md rounded px-8 pt-6 pb-8 mb-4"
    >
      <div class="mb-4">
        <label
          htmlFor="email"
          className="block text-gray-700 dark:text-indigo-400 text-sm font-bold mb-2"
        >
          Email Address
        </label>
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Your Email"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 
          leading-tight focus:outline-none focus:shadow-outline "
        />
        <ValidationError prefix="Email" field="email" errors={state.errors} />
      </div>
      <div class="mb-4">
        <textarea
          id="message"
          name="message"
          placeholder="White your message here"
          className="block p-2.5 w-full text-md  text-gray-900 bg-gray-50 rounded-lg border 
          border-gray-300 focus:ring-indigo-700 focus:border-indigo-400 
          dark:border-gray-600 dark:placeholder-gray-400 dark:text-indigo-800 dark:focus:ring-blue-500 dark:focus:border-blue-500"
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
      </div>

      <button
        type="submit"
        className="w-full bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 
        px-4 rounded focus:outline-none focus:shadow-outline"
        disabled={state.submitting}
      >
        Send
      </button>
    </form>
  );
}

export default ContactForm;
