import React from "react";
import { RiVideoLine } from "react-icons/ri";
import Loading from "./Loading";
import YoutubeIframe from "./YoutubeIframe";

type Props = {
  name: string;
  embed: string;
};

function Course(props: Props) {
  return (
    <div className="w-full md:w-[48%] lg:w-[30%]  bg-white dark:bg-dark rounded-lg shadow-md">
      <div className="object-cover">
        <YoutubeIframe name={props.name} embed={props.embed} />
      </div>

      <div className="p-3">
        <a href={`${"http://www.youtube.com/watch?v=" + props.embed}`}>
          <h5 className="mb-2 hover:underline hover:text-indigo-800 dark:hover:text-yellow-400 dark:text-indigo-200  hover:duration-100 text-2xl font-bold tracking-loose text-indigo-700">
            {props.name}
          </h5>
        </a>
      </div>
    </div>
  );
}

export default Course;
